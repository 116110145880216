<template>
  <div class="height-100 max_box">
    <div class="audio-box">
      <audio src="audio/bg.mp3" ref="bgAudio"></audio>
      <audio :src="audio1Src" ref="audio1"></audio>
      <audio :src="audio2Src" ref="audio2"></audio>
      <audio :src="audio3Src" ref="audio3"></audio>
      <audio :src="audio4Src" ref="audio4"></audio>
      <audio :src="audio5Src" ref="audio5"></audio>
    </div>
    <div class="card animate__animated animate__fadeIn duration" id="card1">
      <div class="card1_box">
        <p>行情回顾</p>
        <p>{{ extra_param_1[0] }}</p>
        <p></p>
        <p>{{ extra_param_1[1] }}</p>
      </div>
      <div class="card1_box2 none">
        <p>摘要</p>
        <p class="margin-t-18">
          今日{{ extra_param_2[0] }}报收{{ extra_param_2[1] }}元，{{
            extra_param_2[2]
          }}{{ extra_param_2[3] }}%，成交量{{ extra_param_2[4] }}万手，成交额{{
            extra_param_2[5]
          }}亿，主力{{ extra_param_2[6] }}{{ extra_param_2[7] }}万
        </p>
      </div>
      <div class="height-100 width-100">
        <video
          src="video/video9.mp4"
          muted
          width="100%"
          height="100%"
          ref="video1"
        ></video>
      </div>
      <div class="logo-box">
        <img src="img/logo22.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="card_main">
        <div class="card_title">
          <span>盘面表现</span>
          <span>{{ extra_param_1[1] }}</span>
        </div>
        <div class="card_content" v-show="!card2_k">
          <p>
            <span>今日报收</span><span class="num">{{ extra_param_3[0] }}</span
            >元,{{ extra_param_3[1]
            }}<span class="num">{{ extra_param_3[2] }}%</span>,上证指数{{
              extra_param_3[3]
            }}<span class="num">{{ extra_param_3[4] }}%</span>,
            {{ extra_param_3[5] }}行业{{ extra_param_3[6]
            }}<span class="num">{{ extra_param_3[7] }}%</span>
          </p>
          <p>
            {{ extra_param_3[8] }}在{{ extra_param_3[9] }}行业中涨幅排名第<span
              class="num"
              >{{ Number(extra_param_3[10]) }}</span
            >
          </p>
        </div>
        <div class="chart_content" v-show="!card2_k">
          <div id="card2_chart"></div>
        </div>
        <div class="card2_k_box" v-show="card2_k">
          <p>
            <span class="card2_k_left" :class="isRedState ? 'isred' : 'islu'">{{
              extra_param_8[0]
            }}</span
            ><span
              >高<i
                :class="
                  extra_param_8[3] - extra_param_8[9] > 0
                    ? 'isred'
                    : 'islu'
                "
                >{{ extra_param_8[3] }}</i
              ></span
            ><span
              >开<i
                :class="
                  extra_param_8[4] - extra_param_8[9] > 0
                    ? 'isred'
                    : 'islu'
                "
                >{{ extra_param_8[4] }}</i
              ></span
            ><span
              >换手<i>{{ extra_param_8[5] }}%</i></span
            >
          </p>
          <p>
            <span class="card2_k_left" :class="isRedState ? 'isred' : 'islu'"
              ><i style="margin-left: 0">{{
                extra_param_8[1]
              }}</i
              ><i
                >{{extra_param_8[2]
                }}%</i
              ></span
            ><span
              >低<i
                :class="
                  extra_param_8[6] - extra_param_8[9] > 0
                    ? 'isred'
                    : 'islu'
                "
                >{{ extra_param_8[6] }}</i
              ></span
            ><span
              >额<i
                >{{ extra_param_8[7] }}亿</i
              ></span
            ><span
              >量比<i>{{
                extra_param_8[8]
              }}</i></span
            >
          </p>
        </div>
        <div class="chart_content margin-t-18" v-show="card2_k">
          <div id="card2_chart_k"></div>
        </div>
        <div class="logo-box logo-box2">
          <img src="img/logo22.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="card_main">
        <div class="card_title">
          <span>成交量</span>
          <span>{{ extra_param_1[1] }}</span>
        </div>
        <div class="card_content">
          <p>
            今日报收成交量<span class="num">{{ extra_param_4[0] }}</span
            >万手,五日成交量均值为<span class="num">{{ extra_param_4[1] }}</span
            >万手。
          </p>
        </div>
        <div class="chart_content chart_content2">
          <div id="card3_chart"></div>
        </div>
        <div class="logo-box logo-box2">
          <img src="img/logo22.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="card_main">
        <div class="card_title">
          <span>资金量</span>
          <span>{{ extra_param_1[1] }}</span>
        </div>
        <div class="card_content">
          <p>
            今日主力流入<span class="num">{{ extra_param_5[0] }}</span
            >万,主力流出<span class="num">{{ extra_param_5[1] }}</span
            >万，主力净流入<span class="num">{{ extra_param_5[2] }}</span
            >万，在所属<span>{{ extra_param_5[3] }}</span
            >行业中资金净流入排名第<span class="num">{{
              Number(extra_param_5[4])
            }}</span>
          </p>
        </div>
        <p>今日资金流向（万元）</p>
        <div class="chart_content4">
          <div id="card4_chart1"></div>
          <p>
            主力流入<span>{{ extra_param_6[0] }}</span
            >主力流出<span>{{ extra_param_6[1] }}</span
            >净流入<span>{{ extra_param_6[2] }}</span>
          </p>
          <div id="card4_chart2"></div>
          <div class="card4_chart2_des"><span v-for="(item,index) in chart3_x2" :key="index">{{item}}</span></div>
        </div>
        <div class="logo-box logo-box2">
          <img src="img/logo22.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="card_main">
        <div class="card_title">
          <span>筹码分布</span>
          <span>{{ extra_param_1[1] }}</span>
        </div>
        <div class="card_content">
          <p>
            <span class="num">{{ extra_param_7.sUpdateDate }}</span
            >日收盘价为<span class="num">{{ extra_param_7.fClosePrice }}</span
            >,压力位<span class="num">{{ extra_param_7.fPressPrice }}</span
            >,支撑位<span class="num">{{ extra_param_7.fSupportPrice }}</span
            >,平均成本<span class="num">{{ extra_param_7.fAvgCost }}</span
            >,目前盈利比例<span class="num">{{
              (extra_param_7.fEarningPer * 100).toFixed(2) + "%"
            }}</span>
          </p>
        </div>
        <p class="progess">
          <span>盈利比例</span><span><i class="progess_i"></i></span
          ><span class="num">{{
            (extra_param_7.fEarningPer * 100).toFixed(2) + "%"
          }}</span>
        </p>
        <div class="line_leged">
          <p class="line_press">
            <span></span>
            <span>压力位</span>
          </p>
          <p class="line_avge">
            <span></span>
            <span>平均成本</span>
          </p>
          <p class="line_support">
            <span></span>
            <span>支撑位</span>
          </p>
        </div>
        <div class="chart_content">
          <div class="d3_chart d3_chip" id="card5_chart"></div>
        </div>
        <div class="logo-box logo-box2">
          <img src="img/logo22.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card6"
    >
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/end.mp4"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { chipChart } from "../utils/chipChart";
import { dataProcess } from "../utils/dataProcess";
import { GetParams } from "../utils/common";
import ApiServe from "@/api/index";
export default {
  name: "Template22",
  data() {
    return {
      audio1Src: "",
      audio2Src: "",
      audio3Src: "",
      audio4Src: "",
      audio5Src: "",
      extra_param_1: [],
      extra_param_2: [],
      extra_param_3: [],
      extra_param_4: [],
      extra_param_5: [],
      extra_param_6: [],
      extra_param_7: {
        sUpdateDate: "",
        fClosePrice: "",
        fPressPrice: "",
        fSupportPrice: "",
        fAvgCost: "",
        fEarningPer: "",
      },
      extra_param_8: [],
      chart1_x: [],
      chart1_y1: [], //个股
      chart1_y2: [], //大盘
      chart1_y3: [], //行业
      chart1_k: [], //日k图
      chart1_k_x: [], //日k图的x轴
      chart2_x: [], //成交量x轴
      chart2_y: [], //成交量y轴
      chart3_1: [], //资金量的饼状图
      chart3_x2: [], //资金量的柱状图
      chart3_y2: [], //资金量的柱状图
      chart5_d3: [],
      device_id: null,
      card2_k: false,
    };
  },
  created() {
    this.device_id = GetParams().device_id || null;
    this.getData();
    this.getAudio();
  },
  computed: {
    allReady() {
      const { extra_param_1, chart1_x,audio1Src } = this;
      return {
        extra_param_1,
        chart1_x,
        audio1Src
      };
    },
    isRedState() {
      if (this.extra_param_8[0] - this.extra_param_8[9] > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    allReady(val) {
      console.log(val);
      if (val.extra_param_1.length > 0 && val.chart1_x.length > 0 && val.audio1Src) {
        if (this.device_id) {
          setTimeout(() => {
            ApiServe.recordStart({
              model_id: 22,
              device_id: this.device_id,
              record_time: 67,
              real: 1,
            }).then((res) => {
              this.load();
            });
          }, 10000);
        } else {
          setTimeout(() => {
            this.load();
          }, 3000);
        }
      }
    },
  },
  methods: {
    getData() {
      ApiServe.getData({ model_id: 22 })
        .then(({ data }) => {
          this.extra_param_1 = data.extra_param_1;
          this.extra_param_2 = data.extra_param_2;
          this.extra_param_3 = data.extra_param_3;
          this.extra_param_4 = data.extra_param_4;
          this.extra_param_5 = data.extra_param_5;
          this.extra_param_6 = data.extra_param_6;
          this.extra_param_8 = data.extra_param_8
          this.getStock(data.chart_data_1[0].url);
          this.getBig(data.chart_data_1[1].url);
          this.getHangye(data.chart_data_1[2].url);
          this.getRiK(data.chart_data_1[3].url);
          this.getCmfbwz(data.extra_param_7.url, data.extra_param_7.payload);
          this.chart2_x = data.chart_data_2[0].data.x || [];
          this.chart2_y = data.chart_data_2[0].data.y || [];
          this.chart3_1 = (data.chart_data_3[0].data["类型"] || []).map(
            (item, index) => {
              return {
                value: data.chart_data_3[0].data["比例"][index],
                name: item,
              };
            }
          );
          this.chart3_x2 = data.chart_data_4[0].data.x || [];
          this.chart3_y2 = data.chart_data_4[0].data.y || [];
        })
        .catch((err) => {
          if (this.device_id) {
            ApiServe.releasedSevice({ device_id: this.device_id });
          }
        });
    },
    // 获取旁白
    getAudio() {
      ApiServe.getVideoData({ model_id: 22 })
        .then(({ data, code }) => {
          if (code == 200) {
            this.audio1Src = data[0].audio_link;
            this.audio2Src = data[1].audio_link;
            this.audio3Src = data[2].audio_link;
            this.audio4Src = data[3].audio_link;
            this.audio5Src = data[4].audio_link;
          }
        })
        .catch((err) => {
          if (this.device_id) {
            ApiServe.releasedSevice({ device_id: this.device_id });
          }
        });
    },
    // 获取个股数据接口
    async getStock(url) {
      let { content, ret } = await this.$axios.get(url);
      if (ret == 0) {
        const fPreClosePrice = JSON.parse(content).fPreClosePrice;
        JSON.parse(content).vTrendDesc.forEach((item, index) => {
          this.chart1_x.push(
            Math.floor(item.iMinute / 60) +
              ":" +
              (item.iMinute % 60 > 9
                ? item.iMinute % 60
                : "0" + (item.iMinute % 60))
          );
          this.chart1_y1.push(
            ((item.fNow - fPreClosePrice) / fPreClosePrice) * 100
          );
        });
      }
    },
    // 获取大盘数据接口
    async getBig(url) {
      let { content, ret } = await this.$axios.get(url);
      if (ret == 0) {
        const fPreClosePrice = JSON.parse(content).fPreClosePrice;
        JSON.parse(content).vTrendDesc.forEach((item, index) => {
          this.chart1_y2.push(
            ((item.fNow - fPreClosePrice) / fPreClosePrice) * 100
          );
        });
      }
    },
    // 获取行业数据接口
    async getHangye(url) {
      let { content, ret } = await this.$axios.get(url);
      if (ret == 0) {
        const fPreClosePrice = JSON.parse(content).fPreClosePrice;
        JSON.parse(content).vTrendDesc.forEach((item, index) => {
          this.chart1_y3.push(
            ((item.fNow - fPreClosePrice) / fPreClosePrice) * 100
          );
        });
      }
    },
    // 获取日K数据接口
    async getRiK(url) {
      let { content, ret } = await this.$axios.get(url);
      if (ret == 0) {
        this.chart1_k = JSON.parse(content).vKLineDesc.map((item) => {
          return [item.fOpen, item.fClose, item.fLow, item.fHigh];
        });
        this.chart1_k_x = JSON.parse(content).vKLineDesc.map((item) => {
          return item.lYmd;
        });
      }
    },
    // 获取筹码分布数据
    async getCmfbwz(url, params) {
      let { content, ret } = await this.$axios.post(url, params);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtChipDistRsp;
        this.dataList = dataObj;
        this.extra_param_7 = dataObj[0]; //筹码分布文字
        //获得所有帧里的最大值和最小值
        var minMax = dataProcess.getMinMax(dataObj, "vStockChipDist");
        //数据格式转换供图表用
        for (var i = 0; i < dataObj.length; i++) {
          dataObj[i].dataConverted = dataProcess.convertData({
            data: dataObj[i].vStockChipDist,
            name: "fPrice",
            value: "fPer",
            avePrice: dataObj[i].fAvgCost,
            closePrice: dataObj[i].fClosePrice,
            pressPrice: dataObj[i].fPressPrice,
            supportPrice: dataObj[i].fSupportPrice,
            xMax: minMax.xMax,
            yMax: minMax.yMax,
            yMin: minMax.yMin,
          });
        }
        this.chart5_d3 = JSON.parse(JSON.stringify(dataObj)).reverse();
      }
    },
    // 盘面表现图表
    loadChart1() {
      var myChart = this.$echarts.init(document.getElementById("card2_chart"));
      var option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["个股", "大盘", "行业"],
        },
        grid: {
          left: "4%",
          right: "0",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chart1_x,
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          position: "right",
          type: "value",
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisLabel: {
            margin: 16,
            formatter: function (value) {
              return value.toFixed(2) + "%";
            },
          },
        },
        series: [
          {
            name: "个股",
            type: "line",
            color: "#5260DB",
            data: this.chart1_y1,
            animationDelay: function (idx) {
              return idx * 100;
            },
          },
          {
            name: "大盘",
            type: "line",
            color: "#D65B5B",
            data: this.chart1_y2,
            animationDelay: function (idx) {
              return idx * 100 + 100;
            },
          },
          {
            name: "行业",
            type: "line",
            color: "#5DE9A7",
            data: this.chart1_y3,
            animationDelay: function (idx) {
              return idx * 100 + 200;
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 盘面表现-日K图
    loadChart1_k() {
      var myChart = this.$echarts.init(
        document.getElementById("card2_chart_k")
      );
      var option = (option = {
        backgroundColor: "#fff", // 背景色
        xAxis: {
          //x轴设置
          type: "category", // 坐标轴类型。'value' 数值轴，适用于连续数据。'category' 类目轴，适用于离散的类目数据.'time' 时间轴，适用于连续的时序数据.'log' 对数轴。适用于对数数据。
          data: this.chart1_k_x, // x轴数据循环方法返回结果
          axisLine: { lineStyle: { color: "#8392A5" } }, // 底部x轴颜色
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          // y轴设置
          scale: true, //是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度。在双数值轴的散点图中比较有用。
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          }, //是否显示分隔线。默认数值轴显示，类目轴不显示。
          axisTick: {
            show: false,
          },
          axisLine: {
            //y轴
            show: false,
          },
        },
        grid: {
          //直角坐标系内绘图网格，单个 grid 内最多可以放置上下两个 X 轴，左右两个 Y 轴。可以在网格上绘制折线图，柱状图，散点图（气泡图）。
          bottom: 20, //grid 组件离容器下侧的距离。单位像素
          right: 10,
          top: 20,
        },
        // dataZoom: [
        //   {
        //     //组件 用于区域缩放，从而能自由关注细节的数据信息，或者概览数据整体，或者去除离群点的影响。就是滚轴上下滚的缩放
        //     textStyle: {
        //       color: "#8392A5",
        //     },
        //     handleIcon:
        //       "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z", // 鼠标放在柱上会出来的图标,默认小手
        //     handleSize: "80%", //控制手柄的尺寸，可以是像素大小，也可以是相对于 dataZoom 组件宽度的百分比，默认跟 dataZoom 宽度相同。
        //     dataBackground: {
        //       //数据阴影的样式。
        //       areaStyle: {
        //         //阴影的填充样式
        //         color: "#8392A5",
        //       },
        //       lineStyle: {
        //         //阴影的线条样式
        //         opacity: 0.8,
        //         color: "#8392A5",
        //       },
        //     },
        //     handleStyle: {
        //       //手柄的样式配置
        //       color: "#fff",
        //       shadowBlur: 3, //图形阴影的模糊大小
        //       shadowColor: "rgba(0, 0, 0, 0.6)",
        //       shadowOffsetX: 2,
        //       shadowOffsetY: 2,
        //     },
        //   },
        //   {
        //     type: "inside",
        //   },
        // ],
        animation: false, // 翻页动画
        // 这里开始设置日k线,如单日,5日,10日,20日,30日
        series: [
          //系列列表。每个系列通过 type 决定自己的图表类型
          {
            type: "candlestick", // 显示种类,如线性等
            name: "日K",
            data: this.chart1_k, // 日k线每日的数据
            itemStyle: {
              // 对象样式
              normal: {
                color: "#FD1050",
                color0: "#0CF49B",
                borderColor: "#FD1050",
                borderColor0: "#0CF49B",
              },
            },
          },
          // {
          //     name: 'MA5',// 5日k线
          //     type: 'line',
          //     data: calculateMA(5, this.chart1_k),// 使用它返回数据方法(几天，数据)
          //     smooth: true,//平滑曲线显示(好看丝滑)
          //     showSymbol: true,//是否显示 symbol, 如果 false 则只有在 tooltip hover 的时候显示。
          //     lineStyle: {
          //         normal: {
          //             width: 1// 5日k线粗细
          //         }
          //     }
          // },
          // {
          //     name: 'MA10',
          //     type: 'line',
          //     data: calculateMA(10, data),
          //     smooth: true,
          //     showSymbol: false,
          //     lineStyle: {
          //         normal: {
          //             width: 1
          //         }
          //     }
          // },
          // {
          //     name: 'MA20',
          //     type: 'line',
          //     data: calculateMA(20, data),
          //     smooth: true,
          //     showSymbol: false,
          //     lineStyle: {
          //         normal: {
          //             width: 1
          //         }
          //     }
          // },
          // {
          //     name: 'MA30',
          //     type: 'line',
          //     data: calculateMA(30, data),
          //     smooth: true,
          //     showSymbol: false,
          //     lineStyle: {
          //         normal: {
          //             width: 1
          //         }
          //     }
          // }
        ],
      });
      myChart.setOption(option);
    },
    // 成交量
    loadChart2() {
      var myChart = this.$echarts.init(document.getElementById("card3_chart"));
      var option = {
        legend: {
          data: ["成交量(万手)"],
          left: "0",
          textStyle: {
            fontSize: 16,
          },
        },
        grid: {
          left: "0",
          right: "60px",
          bottom: "20px",
          top: "20px",
          // containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.chart2_x,
          axisTick: {
            show: false,
          },
        },
        animationDuration: 4000,
        yAxis: {
          show: false,
        },
        series: [
          {
            data: this.chart2_y,
            name: "成交量(万手)",
            type: "bar",
            barWidth: 20,
            label: {
              show: true,
              position: "top",
            },
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#BABFEF" }, //柱图渐变色
                  { offset: 0.5, color: "#B4B9EC" }, //柱图渐变色
                  { offset: 1, color: "#CEB5A1" }, //柱图渐变色
                ]),
              },
            },
            markLine: {
              symbol: "none",
              label: {
                // distance:[-10,20],
                position: "end",
                formatter: function (val) {
                  return "成交均值" + "\n" + val.value;
                },
              },
              data: [{ type: "average", name: "平均值" }],
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 资金量--饼图
    loadChart3() {
      var myChart = this.$echarts.init(document.getElementById("card4_chart1"));
      var option = {
        animationDuration: 4000,
        series: [
          {
            type: "pie",
            radius: ["40%", "60%"],
            label: {
              formatter: function ({ value, name }) {
                return name + value + "%";
              },
            },
            data: this.chart3_1,
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = ["#6772DD", "#3A49DC", "#DF9E6E", "#DF813C"];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 资金量--柱状图
    loadChart4() {
      var myChart = this.$echarts.init(document.getElementById("card4_chart2"));
      var option = {
        grid: {
          left: "0",
          right: "0",
          bottom: "20px",
          top: "20px",
        },
        xAxis: {
          type: "category",
          data: this.chart3_x2,
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        animationDuration: 4000,
        yAxis: {
          show: false,
        },
        series: [
          {
            type: "bar",
            barWidth: 30,
            label: {
              show: true,
              position: "top",
            },
            data: this.chart3_y2.map((item) => {
              return {
                value: item,
                label: {
                  // 设置显示label
                  show: true,
                  // 设置label的位置
                  position: item > 0 ? "top" : "bottom",
                  // 设置label的文字颜色
                  color: item > 0 ? "#DF813C" : "#3A49DC",
                },
                itemStyle: {
                  color: item > 0 ? "#DF813C" : "#3A49DC",
                },
              };
            }),
          },
        ],
      };
      myChart.setOption(option);
    },
    // 筹码分布
    loadChart5D3(currentPlayingFrame = 0) {
      const totalPlayingFrame = this.chart5_d3.length;
      setTimeout(() => {
        if (currentPlayingFrame < totalPlayingFrame) {
          currentPlayingFrame++;
          chipChart.drawChip({
            id: "card5_chart",
            data: this.chart5_d3[currentPlayingFrame - 1].dataConverted,
            width: document.documentElement.clientWidth - 30,
            ratio: 1.15,
          });
          this.extra_param_7 = this.chart5_d3[currentPlayingFrame - 1]; //渲染图上面的那段话的数字
          let progessNum =
            (this.extra_param_7.fEarningPer * 100).toFixed(2) + "%";
          $(".progess_i").css("width", progessNum);
          this.loadChart5D3(currentPlayingFrame); //渲染图
        }
      }, 300);
    },
    load() {
      this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play(); //背景音乐播放
      this.$refs.video1.play(); //首页的视频播放
      setTimeout(() => {
        $(".card1_box").addClass("none");
        $(".card1_box2").removeClass("none");
        this.$refs.audio1.play()
      }, 3000); //间隔
      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, 16000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.$refs.audio2.play()
        this.loadChart1();
      }, 17000); //间隔
      setTimeout(() => {
        this.card2_k = true;
        this.$nextTick(() => {
          this.loadChart1_k();
        });
      }, 25000);
      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, 27000);
      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.$refs.audio3.play()
        this.loadChart2();
      }, 28000); //间隔
      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, 34000);
      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        this.$refs.audio4.play()
        this.loadChart3();
        this.loadChart4();
      }, 35000); //间隔
      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, 45000);
      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.$refs.audio5.play()
        this.loadChart5D3();
      }, 46000); //间隔
      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, 61000);
      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        this.$refs.endVideo.play();
      }, 62000); //间隔
      // 总时间 67秒
      if (this.device_id) {
        setTimeout(() => {
          ApiServe.recordEnd({
            model_id: 22,
            device_id: this.device_id,
            real: 1,
          });
        }, 72000);
      }
    },
  },
};
</script>
<style scoped>
.max_box {
  background: #fff;
}
.card {
  background: #fff;
  color: #000;
  font-size: 12px;
}
#card1 {
  font-size: 14px;
}
.card1_box {
  position: absolute;
  top: 30%;
  left: 10%;
  right: 10%;
  text-align: center;
  color: #fe7f00;
}
.card1_box p:first-of-type {
  font-size: 28px;
  font-weight: 700;
}
.card1_box p:nth-of-type(2) {
  font-size: 22px;
  margin-top: 24px;
}
.card1_box p:nth-of-type(3) {
  width: 60%;
  height: 4px;
  background: #fe7f00;
  margin: 24px auto;
}
.card1_box2 {
  position: absolute;
  top: 25%;
  left: 17%;
  right: 17%;
  text-align: center;
  color: #fe7f00;
  /* border: 1px solid #ffa034; */
  padding: 24px;
  font-size: 20px;
  line-height: 40px;
}
.card1_box2 > p:first-of-type {
  font-size: 24px;
}
.num {
  color: #f67f2d;
  font-size: 14px;
}
.card_title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.card_title span:first-of-type {
  font-size: 26px;
  font-weight: 700;
  color: #f67f2d;
}
.card_main {
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 0;
  right: 0;
  padding: 0 24px;
}
.card_content {
  margin: 8px 0;
  padding: 8px 16px;
  background: #eee;
  line-height: 28px;
}
.chart_content {
  height: 360px;
}
.chart_content2 {
  height: 400px;
}
.chart_content > div {
  height: 100%;
  width: 100%;
}
.chart_content4 {
  height: 400px;
}
.chart_content4 > p {
  font-size: 12px;
  text-align: center;
}
.chart_content4 > p span:first-of-type {
  color: #df813c;
  margin-right: 8px;
}
.chart_content4 > p span:nth-of-type(2) {
  color: #3a49dc;
  margin-right: 8px;
}
.chart_content4 > p span:nth-of-type(3) {
  color: #df813c;
}
.chart_content4 .card4_chart2_des{
  display: flex;
}
.chart_content4 .card4_chart2_des span{
  width: 25%;
  text-align: center;
}
#card4_chart1 {
  height: 200px;
  width: 100%;
}
#card4_chart2 {
  height: 180px;
  width: 100%;
}
#card5_chart {
  width: 100%;
  height: 360px;
}
.progess,
.line_leged {
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin: 8px 16px;
}
.line_leged .line_press {
  color: #be10e3;
}
.line_leged .line_press span:first-of-type {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #be10e3;
}
.line_leged .line_avge {
  color: #ffa942;
  margin: 0 32px;
}
.line_leged .line_avge span:first-of-type {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ffa942;
}
.line_leged .line_support {
  color: #2b7dc7;
}
.line_leged .line_support span:first-of-type {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #2b7dc7;
}
.progess span:nth-of-type(2) {
  width: 100px;
  margin: 0 16px;
  /* background: #f78e3d; */
  height: 10px;
  /* border-radius: 20px; */
}
.progess .progess_i {
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background: #df813c;
}
.card2_k_box{
  background: #eee;
  padding-bottom: 8px;
}
.card2_k_box p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.card2_k_box p span:nth-of-type(2),
.card2_k_box p span:nth-of-type(3) {
  flex: 1;
}
.card2_k_box p .card2_k_left {
  width: 100px;
  text-align: center;
}
.card2_k_box p span:nth-of-type(4) {
  width: 80px;
  text-align: center;
}
.card2_k_box p span i {
  margin-left: 4px;
  font-size: 14px;
}
.card2_k_box > p:first-of-type .card2_k_left {
  font-size: 22px;
  text-align: center;
}
.islu {
  color: #00b466;
}
.isred {
  color: #e54444;
}
</style>